/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import { hidden } from "../styles/utils"

const icons = {
  //wave
  triangle: {
    shape: (
      <React.Fragment>
        <path fill="currentColor" d="M94.42,143.113c8.82-8.82,23.171-8.82,31.993,0c24.416,24.416,64.145,24.416,88.561,0
		c4.273-4.273,9.954-6.626,15.997-6.626s11.724,2.354,15.997,6.626c24.416,24.416,64.145,24.416,88.561,0
		c4.273-4.273,9.954-6.626,15.997-6.626s11.724,2.354,15.997,6.626c11.828,11.828,27.554,18.342,44.28,18.342
		c16.728,0,32.453-6.514,44.281-18.342c7.811-7.811,7.81-20.474-0.001-28.284c-7.811-7.811-20.474-7.811-28.284,0
		c-4.272,4.273-9.953,6.626-15.996,6.626s-11.724-2.354-15.996-6.625c-11.827-11.828-27.554-18.342-44.28-18.343
		c-0.002,0,0,0-0.001,0c-16.726,0-32.453,6.515-44.281,18.342c-8.82,8.821-23.174,8.819-31.992,0.001
		c-11.827-11.828-27.554-18.342-44.28-18.343c-0.002,0,0,0-0.001,0c-16.726,0-32.453,6.515-44.281,18.342
		c-8.82,8.821-23.174,8.82-31.992,0c-24.417-24.417-64.146-24.417-88.562,0c-8.82,8.82-23.172,8.82-31.994,0
		c-7.811-7.811-20.474-7.811-28.284,0s-7.81,20.474,0.001,28.284C30.274,167.529,70.002,167.529,94.42,143.113z"/>
	<path fill="currentColor" d="M427.799,216.829c-4.272,4.273-9.953,6.626-15.996,6.626s-11.724-2.354-15.996-6.625
		c-11.827-11.828-27.554-18.342-44.28-18.343c-0.002,0,0,0-0.001,0c-16.726,0-32.453,6.515-44.281,18.342
		c-8.82,8.821-23.174,8.819-31.992,0.001c-11.827-11.828-27.554-18.342-44.28-18.343c-0.002,0,0,0-0.001,0
		c-16.726,0-32.453,6.515-44.281,18.342c-8.82,8.821-23.174,8.82-31.992,0c-24.417-24.417-64.146-24.417-88.562,0
		c-8.82,8.82-23.172,8.82-31.994,0c-7.811-7.811-20.474-7.811-28.284,0s-7.81,20.474,0.001,28.284
		c24.416,24.416,64.144,24.416,88.562,0c8.82-8.82,23.171-8.82,31.993,0c24.416,24.416,64.145,24.416,88.561,0
		c4.273-4.273,9.954-6.626,15.997-6.626s11.724,2.354,15.997,6.626c24.416,24.416,64.145,24.416,88.561,0
		c4.273-4.273,9.954-6.626,15.997-6.626s11.724,2.354,15.997,6.626c11.828,11.828,27.554,18.342,44.28,18.342
		c16.728,0,32.453-6.514,44.281-18.342c7.811-7.811,7.81-20.474-0.001-28.284C448.272,209.018,435.609,209.018,427.799,216.829z"/>
	<path fill="currentColor" d="M427.799,318.829c-4.272,4.273-9.953,6.626-15.996,6.626s-11.724-2.354-15.996-6.625
		c-11.827-11.828-27.554-18.342-44.28-18.343c-0.002,0,0,0-0.001,0c-16.726,0-32.453,6.515-44.281,18.342
		c-8.82,8.821-23.174,8.819-31.992,0.001c-11.827-11.828-27.554-18.342-44.28-18.343c-0.002,0,0,0-0.001,0
		c-16.726,0-32.453,6.515-44.281,18.342c-8.82,8.82-23.174,8.821-31.992,0c-24.417-24.417-64.146-24.417-88.562,0
		c-8.82,8.82-23.172,8.82-31.994,0c-7.811-7.811-20.474-7.811-28.284,0s-7.81,20.474,0.001,28.284
		c24.416,24.416,64.144,24.416,88.562,0c8.82-8.82,23.171-8.82,31.993,0c24.416,24.416,64.145,24.416,88.561,0
		c4.273-4.273,9.954-6.626,15.997-6.626s11.724,2.354,15.997,6.626c24.416,24.416,64.145,24.416,88.561,0
		c4.273-4.273,9.954-6.626,15.997-6.626s11.724,2.354,15.997,6.626c11.828,11.828,27.554,18.342,44.28,18.342
		c16.728,0,32.453-6.514,44.281-18.342c7.811-7.811,7.81-20.474-0.001-28.284C448.272,311.018,435.609,311.018,427.799,318.829z"/>

      </React.Fragment>
    ),
    viewBox: `0 0 461.941 461.941`,
    // shape: (
    //   <polygon
    //     strokeWidth="1px"
    //     strokeLinejoin="round"
    //     strokeMiterlimit="10"
    //     points="14.921,2.27 28.667,25.5 1.175,25.5 "
    //   />
    // ),
    // viewBox: `0 0 30 30`,
  },
  //gear
  circle: {
    shape: (
      <React.Fragment>
      <path d="M0,0h24v24H0V0z" fill="none"/>
      <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"/>
      </React.Fragment>
    ),
    viewBox: `0 0 30 30`,
  },
  //wind
  arrowUp: {
    shape: (
      <path d="M28.69,53.38c-1.61,0-2.91-1.3-2.91-2.91c0-1.61,1.3-2.91,2.91-2.91h51.37c0.21,0,0.42,0.02,0.62,0.07 c1.84,0.28,3.56,0.8,5.1,1.63c1.7,0.92,3.15,2.19,4.27,3.89c3.85,5.83,3.28,11.24,0.56,15.24c-1.77,2.61-4.47,4.55-7.45,5.57 c-3,1.03-6.32,1.13-9.32,0.03c-4.54-1.66-8.22-5.89-8.76-13.55c-0.11-1.6,1.1-2.98,2.7-3.09c1.6-0.11,2.98,1.1,3.09,2.7 c0.35,4.94,2.41,7.56,4.94,8.48c1.71,0.62,3.67,0.54,5.48-0.08c1.84-0.63,3.48-1.79,4.52-3.32c1.49-2.19,1.71-5.28-0.61-8.79 c-0.57-0.86-1.31-1.51-2.18-1.98c-0.91-0.49-1.97-0.81-3.13-0.99H28.69L28.69,53.38z M15.41,27.21c-1.61,0-2.91-1.3-2.91-2.91 c0-1.61,1.3-2.91,2.91-2.91h51.21c1.17-0.18,2.23-0.5,3.14-0.99c0.87-0.47,1.61-1.12,2.18-1.98c2.32-3.51,2.09-6.6,0.61-8.79 c-1.04-1.53-2.68-2.69-4.52-3.32c-1.81-0.62-3.78-0.7-5.48-0.08c-2.52,0.92-4.59,3.54-4.94,8.48c-0.11,1.6-1.49,2.81-3.09,2.7 c-1.6-0.11-2.81-1.49-2.7-3.09c0.54-7.66,4.22-11.89,8.76-13.55c3-1.09,6.32-0.99,9.32,0.03c2.98,1.02,5.68,2.97,7.45,5.57 c2.72,4,3.29,9.41-0.56,15.24c-1.12,1.7-2.57,2.97-4.27,3.89c-1.54,0.83-3.26,1.35-5.1,1.63c-0.2,0.04-0.41,0.07-0.62,0.07H15.41 L15.41,27.21z M2.91,40.3C1.3,40.3,0,38.99,0,37.39c0-1.61,1.3-2.91,2.91-2.91h107.07c1.17-0.18,2.23-0.5,3.13-0.99 c0.87-0.47,1.61-1.12,2.18-1.98c2.32-3.51,2.09-6.6,0.61-8.79c-1.04-1.53-2.68-2.69-4.52-3.32c-1.81-0.62-3.78-0.7-5.48-0.08 c-2.52,0.92-4.59,3.54-4.94,8.48c-0.11,1.6-1.49,2.81-3.09,2.7c-1.6-0.11-2.81-1.49-2.7-3.09c0.54-7.66,4.22-11.89,8.76-13.55 c3-1.09,6.32-0.99,9.32,0.03c2.98,1.02,5.68,2.97,7.45,5.57c2.72,4,3.29,9.41-0.56,15.24c-1.12,1.7-2.57,2.97-4.27,3.89 c-1.54,0.83-3.26,1.35-5.1,1.63c-0.2,0.04-0.41,0.07-0.62,0.07H2.91L2.91,40.3z"/>
    ),
    viewBox: `0 0 122.88 74.78`,
    // shape: (
    //   <React.Fragment>
    //     <path d="M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z" />
    //     {` `}
    //     <path d="M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z" />
    //   </React.Fragment>
    // ),
    // viewBox: `0 0 30 42`,
  },
  //snow
  upDown: {
    shape: (
      <path d="M23 47.689v-6.342l-3.357 1.992-1.643-1.034v-2.229l5-2.986v-4.168l-4 2.451v-4.416l-4 2.094v5.99l-1.653 1.23-1.347-1.111v-4.012l-5.574 3.122-2.426-.999v-2.529l5.685-3.17-3.685-1.822v-2.32l2.123-1.127 5.214 3.068 3.612-2.084-.082-.065-3.665-2.123 3.568-2.228-3.577-2.083-5.213 3.052-1.98-.969v-2.307l3.542-1.978-5.542-3.053v-2.529l2.321-1.114 5.679 3.197v-4.076l1.485-1.127 1.943 1.18-.056 6.105 3.673 2.122.033-4.311 3.922 2.099v-4.167l-5-2.988v-2.214l1.643-1.05 3.357 1.992v-6.328l1.994-1.324 2.006 1.324v6.328l3.906-2.031 2.094 1.219v1.992l-6 3.08v4.167l4-2.267v4.534l4-2.084v-6.524l1.455-.866 1.545.865v4.167l5.842-3.08 2.158 1.218v2.359l-5.495 3.17 3.495 1.954v2.254l-1.83.996-5.327-3.158-3.679 2.346 3.549 2.228-3.659 2.122 3.772 1.992 5.389-2.986 1.785 1.216v2.15l-3.32 1.887 5.32 3.17v2.49l-2.522 1.037-5.478-2.988v3.955l-1.52 1.049-1.48-1.049v-6.002l-4-2.213v4.168l-4-2.268v4.168l5 2.986v2.359l-1.647.904-3.353-1.99v6.342l-2.006 1.31-1.994-1.311zm-1.466-22.597l1.886 2.908h3.514l1.613-2.908-1.704-3.092h-3.514l-1.795 3.092z"/>
    ),
    viewBox: `0 0 50 50`,
    // shape: (
    //   <React.Fragment>
    //     <path d="M28.74,44.58a1.28,1.28,0,0,1-1-.51L15.16,27.13l-12.89,17a1.26,1.26,0,1,1-2-1.53l13.9-18.29a1.34,1.34,0,0,1,1-.5,1.24,1.24,0,0,1,1,.51L29.75,42.56a1.27,1.27,0,0,1-1,2Z" />
    //     <path d="M14.83,20.82h0a1.28,1.28,0,0,1-1-.52L.25,2a1.27,1.27,0,0,1,2-1.51L14.84,17.45,27.73.5a1.26,1.26,0,0,1,2,1.53L15.84,20.32A1.28,1.28,0,0,1,14.83,20.82Z" />
    //   </React.Fragment>
    // ),
    // viewBox: `0 0 30 44.58`,
  },
  //computer
  box: {
    shape: (
      <path d="M28.89,20.82h65a8.42,8.42,0,0,1,8.39,8.4V94A8.4,8.4,0,0,1,99.8,99.9h0a8.36,8.36,0,0,1-5.92,2.47h-65a8.43,8.43,0,0,1-8.4-8.4V29.22A8.37,8.37,0,0,1,23,23.3h0a8.37,8.37,0,0,1,5.92-2.47ZM45.47,37.51H77.29a8,8,0,0,1,8,8V77.71a8,8,0,0,1-8,8H45.47a8,8,0,0,1-8-8V45.49a8,8,0,0,1,8-8ZM91.88,89.16a2.94,2.94,0,1,1-2.94-2.93,2.94,2.94,0,0,1,2.94,2.93ZM36.75,34a2.94,2.94,0,1,1-2.94-2.93A2.94,2.94,0,0,1,36.75,34ZM88.94,31.1A2.94,2.94,0,1,1,86,34a2.94,2.94,0,0,1,2.93-2.93ZM33.81,86.23a2.94,2.94,0,1,1-2.93,2.93,2.94,2.94,0,0,1,2.93-2.93Zm.7,22.76v13.86H29.34V109Zm11.94,0v13.86H41.28V109Zm11.93,0v13.86H53.21V109Zm11.94,0v13.86H65.15V109Zm11.93,0v13.86H77.08V109Zm11.94,0v13.86H89V109ZM34.51,0V13.86H29.34V0ZM46.45,0V13.86H41.28V0ZM58.38,0V13.86H53.21V0ZM70.32,0V13.86H65.15V0ZM82.25,0V13.86H77.08V0ZM94.19,0V13.86H89V0ZM109,88.81h13.86V94H109V88.81Zm0-11.94h13.86V82H109V76.87Zm0-11.93h13.86v5.17H109V64.94ZM109,53h13.86v5.17H109V53Zm0-11.93h13.86v5.17H109V41.07Zm0-11.94h13.86V34.3H109V29.13ZM0,88.81H13.86V94H0V88.81ZM0,76.87H13.86V82H0V76.87ZM0,64.94H13.86v5.17H0V64.94ZM0,53H13.86v5.17H0V53ZM0,41.07H13.86v5.17H0V41.07ZM0,29.13H13.86V34.3H0V29.13Zm93.87-4.28h-65a4.39,4.39,0,0,0-3.09,1.27h0a4.34,4.34,0,0,0-1.27,3.09V94a4.39,4.39,0,0,0,4.37,4.38h65A4.35,4.35,0,0,0,97,97.07h0A4.35,4.35,0,0,0,98.24,94V29.22a4.39,4.39,0,0,0-4.37-4.37Z" />
    ),
    viewBox: `0 0 122.88 122.85`,
    // shape: (
    //   <path d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z" />
    // ),
    // viewBox: `0 0 30 30`,
  },
  //van
  hexa: {
    shape: (
      <React.Fragment>
        <path fill="currentColor" d="m409.326,211.562c0-4.142-3.357-7.5-7.5-7.5h-333.652c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5h333.652c4.143,0 7.5-3.358 7.5-7.5z"/>
        <path fill="currentColor" d="m116.411,362.655h-48.911c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5h48.911c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z"/>
        <path fill="currentColor" d="m313.589,362.655h-157.178c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5h157.178c4.143,0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z"/>
        <path fill="currentColor" d="m402.5,362.655h-48.911c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5h48.911c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z"/>
        <path fill="currentColor" d="m296.304,299.978l-7.914,21.131h-106.78l-7.914-21.131c-1.452-3.878-5.772-5.845-9.654-4.393-3.879,1.453-5.846,5.775-4.393,9.654l9.738,26c1.097,2.929 3.896,4.869 7.023,4.869h117.178c3.127,0 5.927-1.94 7.023-4.869l9.738-26c1.453-3.879-0.514-8.202-4.393-9.654-3.879-1.454-8.2,0.513-9.652,4.393z"/>
        <path fill="currentColor" d="M462.5,154.062c-4.143,0-7.5,3.358-7.5,7.5v42.5h-16.431L413.401,86.019c-0.005-0.024-0.01-0.048-0.016-0.073   l-12.889-60.449C397.45,11.2,383.611,0,368.992,0H101.008C86.39,0,72.551,11.199,69.502,25.497L56.61,85.963   c-0.01,0.044-25.179,118.099-25.179,118.099H15v-42.5c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v50c0,4.142,3.357,7.5,7.5,7.5   H30V390.2c0,12.563,8.472,23.178,20,26.452V462.5c0,4.142,3.357,7.5,7.5,7.5h68.911c4.143,0,7.5-3.358,7.5-7.5v-22.25   c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V455H65v-37.3h340V455h-53.911v-14.75c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5   v22.25c0,4.142,3.357,7.5,7.5,7.5H412.5c4.143,0,7.5-3.358,7.5-7.5v-45.848c11.528-3.274,20-13.889,20-26.452V219.062h22.5   c4.143,0,7.5-3.358,7.5-7.5v-50C470,157.419,466.643,154.062,462.5,154.062z M84.172,28.625C85.72,21.367,93.587,15,101.008,15   h267.984c7.421,0,15.288,6.366,16.835,13.625L396.781,80H73.218L84.172,28.625z M412.5,402.7h-355c-6.893,0-12.5-5.607-12.5-12.5   v-54.091h91.411c2.459,0,4.763-1.206,6.164-3.227c1.4-2.021,1.722-4.601,0.859-6.904l-9.738-26   c-3.975-10.607-15.959-18.916-27.285-18.916H72.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h33.911   c5.129,0,11.438,4.375,13.238,9.178l5.943,15.869H45V212.352L70.02,95h329.959L425,212.352v108.756h-80.593l5.943-15.869   c1.8-4.804,8.109-9.178,13.238-9.178H397.5c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-33.911   c-11.326,0-23.311,8.309-27.285,18.916l-9.738,26c-0.862,2.303-0.541,4.883,0.859,6.904c1.401,2.021,3.705,3.227,6.164,3.227H425   V390.2C425,397.092,419.393,402.7,412.5,402.7z"/>
      </React.Fragment>
    ),
    viewBox: `0 0 470 470`,
    // shape: (
    //   <polygon
    //     strokeLinejoin="round"
    //     strokeMiterlimit="10"
    //     points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
    //   />
    // ),
    // viewBox: `0 0 30 30`,
  },
  // wrench
  cross: {
    shape: (
      <path
        fill="currentColor"
        d="M22.72,70.1c-8.37,1.51-16.51,4.35-20.93,15.44c-2.87,7.19-2.21,15.85,1.44,23.7 c9.88-30.07,44.48-12.31,22.22,13.6c16.29,0.81,26.61-10.58,28.23-25.95c0.77-7.26,1.4-11.17,2.99-14.3 c0.85-1.73,2.03-3.17,3.61-4.9c5.34-5.86,11.28-10.98,17.1-17.06c10.95-11.46,34.31-0.88,43.23-23.29 c2.87-7.19,2.21-15.85-1.44-23.7c-9.88,30.07-44.48,12.31-22.22-13.6C80.66-0.77,70.34,10.62,68.72,25.99 c-0.7,6.74,0.48,12.49-7,20.2c-5.27,5.27-10.84,10.61-16.25,15.77c-2.14,2.06-3.69,3.32-5.49,4.2c-3.24,1.62-7.26,2.1-17.17,3.87 L22.72,70.1L22.72,70.1z"
      />
    ),
    viewBox: `0 0 122.4 122.88`,
    // shape: (
    //   <path
    //     strokeWidth="3px"
    //     d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
    //   />
    // ),
    // viewBox: `0 0 100 100`,
  },
}

type IconType = "triangle" | "circle" | "arrowUp" | "upDown" | "box" | "hexa" | "cross"

type SVGProps = {
  stroke?: boolean
  color?: string | number | any
  width: number
  icon: IconType
  left: string
  top: string
  hiddenMobile?: boolean
}

const Svg = ({ stroke = false, color = ``, width, icon, left, top, hiddenMobile = false }: SVGProps) => (
  <svg
    sx={{
      position: `absolute`,
      stroke: stroke ? `currentColor` : `none`,
      fill: stroke ? `none` : `currentColor`,
      display: hiddenMobile ? hidden : `block`,
      color,
      width,
      left,
      top,
    }}
    viewBox={icons[icon].viewBox}
  >
    {icons[icon].shape}
  </svg>
)

export default Svg
